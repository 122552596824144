import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Slider from "react-slick";

const SliderPhoto = () => {
  function Img1() {
    return (
      <StaticImage
        src="../images/humans-slider/s1a.jpg"
        alt=""
        placeholder="blurred"
        layout="constrained"
        height="350"
        width="528"
        formats={["auto", "webp"]}
      />
    );
  }

  function Img2() {
    return (
      <StaticImage
        src="../images/humans-slider/s2a.jpg"
        alt=""
        placeholder="blurred"
        layout="constrained"
        height="350"
        width="232"
        formats={["auto", "webp"]}
      />
    );
  }

  function Img3() {
    return (
      <StaticImage
        src="../images/humans-slider/s3a.jpg"
        alt=""
        placeholder="blurred"
        layout="constrained"
        height="350"
        width="531.52"
        formats={["auto", "webp"]}
      />
    );
  }

  function Img4() {
    return (
      <StaticImage
        src="../images/humans-slider/s4a.jpg"
        alt=""
        placeholder="blurred"
        layout="constrained"
        height="350"
        width="231"
        formats={["auto", "webp"]}
      />
    );
  }

  function Img5() {
    return (
      <StaticImage
        src="../images/humans-slider/s5a.jpg"
        alt=""
        placeholder="blurred"
        layout="constrained"
        height="350"
        width="533.53"
        formats={["auto", "webp"]}
      />
    );
  }

  function Img6() {
    return (
      <StaticImage
        src="../images/humans-slider/s6a.jpg"
        alt=""
        placeholder="blurred"
        layout="constrained"
        height="350"
        width="230"
        formats={["auto", "webp"]}
      />
    );
  }

  const settings = {
    className: "slider variable-width",
    dots: true,
    infinite: true,
    variableWidth: true,
    speed: 500,
    autoplay: true,
  };

  return (
    <>
      <div>
        {/* Color mask div
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(247, 173, 182, 0.45)",
          }}
        /> */}
        {/* Slider container */}
        <div>
          <div className="slider-container mush" style={{ maxHeight: "370px" }}>
            <Slider {...settings}>
              <div style={{ width: 230 }}>{Img6()}</div>
              <div style={{ width: 528 }}>{Img1()}</div>
              <div style={{ width: 232 }}>{Img2()}</div>
              <div style={{ width: 531.52 }}>{Img3()}</div>
              <div style={{ width: 231 }}>{Img4()}</div>
              <div style={{ width: 533.53 }}>{Img5()}</div>
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default SliderPhoto;
